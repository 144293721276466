var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from './lit';
let Paginator = class Paginator extends LitElement {
    static { this.styles = css `
  .pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dfdfdf;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #ececec;
    --bs-pagination-hover-border-color: #dfdfdf;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #ececec;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(50, 130, 204, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #3282CC;
    --bs-pagination-active-border-color: #3282CC;
    --bs-pagination-disabled-color: #999999;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dfdfdf;
    display: flex;
    padding-left: 0px;
    list-style: none;
}

.pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.75rem;
    --bs-pagination-border-radius: 0.25rem;
}

.justify-content-center {
    justify-content: center !important;
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.page-link.disabled, .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
}
.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
}
  `; }
    // Render the UI as a function of component state
    render() {
        return html `
      <nav aria-label="Page navigation">
        <ul class="pagination pagination-sm justify-content-center">
            <li class="page-item disabled">
              <a class="page-link" href="#">First</a>
            </li>
            <li class="page-item disabled">
              <a class="page-link" href="#" rel="prev">Prev</a>
            </li>
            <li class="page-item active"><span class="page-link">1</span></li>
            <li class="page-item active"><span class="page-link">2</span></li>
            <li class="page-item active"><span class="page-link">3</span></li>
            <li class="page-item disabled">
              <a class="page-link" href="#" rel="prev">Next</a>
            </li>
            <li class="page-item disabled">
            <a class="page-link" href="#">Last</a>
            </li>
        </ul>
    </nav>`;
    }
};
Paginator = __decorate([
    customElement('st-paginator')
], Paginator);
export { Paginator };
