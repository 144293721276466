var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable lit/no-classfield-shadowing */
import { LitElement, css, html, nothing, } from 'lit';
import { customElement, property, query, queryAssignedElements, state, } from './lit';
import { styles } from './st-form-select.scss';
// default callback function to get the value from a slotted option.
// just returns the value of the 'value' property
function defaultGetOptionValue(selected) {
    return selected.value;
}
// default callback function to get the value from a slotted option.
// just returns the content of the textContent property
function defaultGetOptionDisplayText(selected) {
    return selected.textContent ?? undefined;
}
function defaultFilterComparer(filterText, option) {
    const caseInsensitiveFilter = filterText.toLowerCase();
    const optionElementWithValue = option;
    if (optionElementWithValue.value !== undefined) {
        if (optionElementWithValue.value.toLowerCase()
            .startsWith(caseInsensitiveFilter)) {
            return true;
        }
    }
    return option.textContent?.toLowerCase().startsWith(caseInsensitiveFilter) ?? false;
}
let AutoComplete = class AutoComplete extends LitElement {
    constructor() {
        super(...arguments);
        this.placeholder = 'Text Search';
        this.disabled = false;
        this._internalValue = '';
        this.displayValue = '';
        this.getOptionValueCallback = defaultGetOptionValue;
        this.getOptionDisplayTextCallback = defaultGetOptionDisplayText;
        this.filterCompare = defaultFilterComparer;
        this.dropdownVisible = false;
        this.inputFilterChanged = (ev) => {
            const inputField = ev.target;
            this.onFilterChange(inputField.value);
            this.showDropdown();
        };
        this.displayTextGetsFocus = (ev) => {
            if (ev.relatedTarget !== this.editableField) {
                this.editableField.focus();
            }
        };
        this.focusEnter = () => {
            this.defaultInput?.select();
            this.showDropdown();
        };
        this.focusLeave = () => {
            if (!this.inmousedown) {
                this.hideDropdown();
            }
        };
        this.inmousedown = false;
        this.dropdownSelected = (ev) => {
            this.selectedElements.forEach(el => el.classList.remove('selected'));
            ev.target.classList.add('selected');
            this.setValueFromDropdownItem();
        };
        this.placePopover = (e) => {
            // this will position the popover underneath the main dropdown element
            const boundingRect = this.getBoundingClientRect();
            const thePopover = e.target;
            thePopover.setAttribute('style', `top: ${boundingRect.bottom}px; --popover-top: ${boundingRect.bottom}px; left: ${boundingRect.left}px;`);
        };
        this.selectDropdownItem = (ev) => {
            switch (ev.code) {
                case 'Enter':
                    this.setValueFromDropdownItem();
                    this.hideDropdown();
                    break;
                case 'Escape':
                    this.hideDropdown();
                    break;
                case 'ArrowUp':
                    this.changeSelectionPrev();
                    break;
                case 'ArrowDown':
                    this.changeSelectionNext();
                    break;
                default:
                    this.selectedElements.forEach(el => el.classList.remove('selected'));
                    break;
            }
        };
        this.onFilterChange = (newValue) => {
            this.slottedElements.forEach((option) => {
                const optionSatisfiesFilter = this.filterCompare(newValue, option);
                if (optionSatisfiesFilter) {
                    option.classList.remove('failed-filter');
                }
                else {
                    option.classList.add('failed-filter');
                }
            });
        };
    }
    static { this.styles = [styles,
        css `
    :host {
      --dropdown-item-highlight-color: silver;
      --bs-info-bg-subtle: #AAAAAA;
    }
    * {
      box-sizing: border-box;
    }

    ::slotted(*) {
      display: block;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    ::slotted(.failed-filter) {
      display:none;
    }

    ::slotted(*:hover) {
      background-color: var(--dropdown-item-highlight-color);
      cursor: default;
    }

    ::slotted(.selected) {
      background-color: var(--dropdown-item-highlight-color);
    }

    [popover] {
      margin: 0;
      max-height: min(300px, calc(100% - var(--popover-top, 50px)));
      border-radius: 4px;
      border-width: 1px;
      box-shadow: 0 10px 10px rgba(0,0,0,.1);
    }

    .wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    .readonly {
      grid-area: 1/1/1/1;
    }

    .editable {
      grid-area: 1/1/1/1;
    }

    .editable {
      display: none;
      opacity: 0;
      border: none;
      outline: none;
    }

    :focus-within {
      .readonly {
        opacity:0;
        display:none;
      }
      .editable {
        display: block;
        opacity: 1;
      }
    }
    .editable:focus {
      opacity: 1;
    }
  `]; }
    // internalValue is the master of the current value of the component. It is really the value of
    // the filter text - which means that it might not match a single option
    get internalValue() {
        return this._internalValue;
    }
    set internalValue(newValue) {
        const oldValue = this._internalValue;
        this._internalValue = newValue;
        setTimeout(() => {
            this.onFilterChange(newValue);
        }, 0);
        this.requestUpdate('internalValue', oldValue);
    }
    // the value attribute is used mainly to set the value from outside this component. We use
    // internalValue to record the actual value (that changes as the user does stuff).
    get value() {
        return this.internalValue;
    }
    set value(value) {
        this.internalValue = value;
        setTimeout(() => {
            // this is in a setTimeout so that it can happen after the rest of the page is rendered.
            // If we don't the value attribute gets set before the option items are added, meaning
            // we can't set the selected option
            this.setSelectedOption(value);
            this.updateDisplayValue();
        }, 0);
    }
    updateDisplayValue() {
        if (this.selectedElements.at(0)) {
            this.displayValue = this.getOptionDisplayTextCallback(this.selectedElements[0]) ?? '';
        }
        else {
            this.displayValue = '';
        }
    }
    render() {
        // Note on the div.readonly tabindex. If you have the lit-plugin in vscode, it will complain
        // about the tabindex conditional rendering. In this case, the plugin is wrong.
        return html `
    <div @focusin=${this.focusEnter} @keydown=${this.selectDropdownItem} >
      <div class="form-control form-select wrapper" >
          <div class="readonly display-text" tabindex=${this.disabled ? nothing : 0} @focus=${this.displayTextGetsFocus} >${this.displayValue}</div>
          <input class="default-input editable"
            type="search"
            spellcheck="false"
            incremental="true"
            .value=${this.internalValue}
            .placeholder=${this.placeholder}
            tabindex="0"
            @input=${this.inputFilterChanged}
            @blur=${this.focusLeave} />
      </div>
      ${this.renderPopover()}
    </div>`;
    }
    renderPopover() {
        return html `
    <div id="dropdown-popover" popover="manual" @beforetoggle=${this.placePopover} >
      <slot @click=${this.dropdownSelected} @mousedown=${this.dropdownMouseDown} ></slot>
    </div>`;
    }
    updated() {
        if (this.dropdownVisible) {
            this.popoverElement?.showPopover();
        }
        else {
            this.popoverElement?.hidePopover();
        }
    }
    showDropdown() {
        this.dropdownVisible = true;
    }
    hideDropdown() {
        this.dropdownVisible = false;
    }
    dropdownMouseDown() {
        // If we just use a click event for item select, it doesn't work. The focus leave hides the
        // dropdown before the click event happens. And because the dropdown is not visible, it doesn't
        // get click events. To fix this, we have to start here, in the mouse down. We stop the focus
        // leave from hiding the dropdown. That lets the click event happen. Then we add a one time
        // mouse up event to the document itself. That will hide the dropdown even if the mouse up
        // event is somewhere else on the page
        this.inmousedown = true;
        document.addEventListener('mouseup', () => {
            this.inmousedown = false;
            this.hideDropdown();
        }, { once: true });
    }
    changeSelectionPrev() {
        const visibleOptions = this.slottedElements.filter(el => !el.classList.contains('failed-filter'));
        if (visibleOptions.length === 0) {
            return;
        }
        let selectedElement = this.selectedElements.at(0);
        if (!selectedElement) {
            // no current selected element. so select the last one (because of prev)
            selectedElement = visibleOptions.at(-1);
        }
        else {
            const selectedElementIndex = visibleOptions.findIndex(visibleOption => visibleOption === selectedElement);
            const newSelectedIndex = selectedElementIndex - 1;
            selectedElement = visibleOptions.at(newSelectedIndex);
        }
        this.selectedElements.forEach(selectedEl => selectedEl.classList.remove('selected'));
        selectedElement?.classList.add('selected');
        selectedElement?.scrollIntoView({ block: 'nearest' });
    }
    changeSelectionNext() {
        const visibleOptions = this.slottedElements.filter(el => !el.classList.contains('failed-filter'));
        if (visibleOptions.length === 0) {
            return;
        }
        let selectedElement = this.selectedElements.at(0);
        if (!selectedElement) {
            // no current selected element. so select the first one
            selectedElement = visibleOptions.at(0);
        }
        else {
            const selectedElementIndex = visibleOptions.findIndex(visibleOption => visibleOption === selectedElement);
            const newSelectedIndex = (selectedElementIndex + 1) % visibleOptions.length;
            selectedElement = visibleOptions.at(newSelectedIndex);
        }
        this.selectedElements.forEach(selectedEl => selectedEl.classList.remove('selected'));
        selectedElement?.classList.add('selected');
        selectedElement?.scrollIntoView({ block: 'nearest' });
    }
    setValueFromDropdownItem() {
        const selectedElement = this.selectedElements.at(0);
        if (selectedElement) {
            const useDefaultSetMethod = this.dispatchEvent(new CustomEvent('selected', { detail: selectedElement, composed: true }));
            if (useDefaultSetMethod) {
                const value = this.getOptionValueCallback(selectedElement);
                this.explicitSetValue(value);
            }
        }
    }
    explicitSetValue(value) {
        if (this.value !== value) {
            this.value = value ?? '';
        }
    }
    setSelectedOption(optionValue) {
        this.slottedElements.forEach((slottedElement) => {
            const slottedValue = this.getOptionValueCallback(slottedElement)?.toLowerCase();
            if (optionValue?.toLowerCase() === slottedValue?.toLowerCase()) {
                slottedElement.classList.add('selected');
            }
            else {
                slottedElement.classList.remove('selected');
            }
        });
    }
};
__decorate([
    property()
], AutoComplete.prototype, "placeholder", void 0);
__decorate([
    property({ type: Boolean })
], AutoComplete.prototype, "disabled", void 0);
__decorate([
    state()
], AutoComplete.prototype, "internalValue", null);
__decorate([
    property()
], AutoComplete.prototype, "value", null);
__decorate([
    state()
], AutoComplete.prototype, "displayValue", void 0);
__decorate([
    property()
], AutoComplete.prototype, "getOptionValueCallback", void 0);
__decorate([
    property()
], AutoComplete.prototype, "getOptionDisplayTextCallback", void 0);
__decorate([
    property()
], AutoComplete.prototype, "filterCompare", void 0);
__decorate([
    state()
], AutoComplete.prototype, "dropdownVisible", void 0);
__decorate([
    query('#dropdown-popover')
], AutoComplete.prototype, "popoverElement", void 0);
__decorate([
    query('input.default-input')
], AutoComplete.prototype, "defaultInput", void 0);
__decorate([
    queryAssignedElements({ selector: '*', flatten: false })
], AutoComplete.prototype, "slottedElements", void 0);
__decorate([
    queryAssignedElements({ selector: '.selected', flatten: true })
], AutoComplete.prototype, "selectedElements", void 0);
__decorate([
    query('.editable')
], AutoComplete.prototype, "editableField", void 0);
AutoComplete = __decorate([
    customElement('st-autocomplete')
], AutoComplete);
export { AutoComplete };
