import {css} from 'lit';
export const styles = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

input {
  margin: 0;
  font-family: inherit;
}

.form-control {
  background-clip: padding-box;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-right: 2rem !important;
  background-color: #fff;
  background-position: right 0.5rem center;
  width: 100%;
  border: 1px solid #BDBDBD !important;
  border-radius: 0 !important;
  padding: 0.2rem 0.35rem;
  height: 34px;
  color: #1e1e1e;
  font-size: 0.875rem;
}

.form-control:focus-within {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #99c1e6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(50, 130, 204, 0.25);
  border-color: #99c1e6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(50, 130, 204, 0.25);
  outline-color: #3282CC !important;
}

.default-input {
  width: 100%;
  border: none;
  background: none;
}
.default-input:focus {
  outline: 0;
}

::slotted(input) {
  outline: 0px;
  border: 0px;
  width: 100%;
}`;
export default styles;
