var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable lit/no-classfield-shadowing */
import { html, LitElement } from 'lit';
import { customElement, property, queryAssignedElements, } from './lit';
import styles from './st-form-select.scss';
let CustomSelect = class CustomSelect extends LitElement {
    constructor() {
        super(...arguments);
        this._value = '';
        this.readonly = false;
    }
    static { this.styles = [styles]; }
    get value() {
        return this._value;
    }
    set value(newValue) {
        const oldValue = this._value;
        this._value = newValue;
        setTimeout(() => { this.setValueInSlottedContent(); }, 0);
        this.requestUpdate('value', oldValue);
    }
    render() {
        return html `
    <div class="form-control form-select" >
      <slot>
        <input class="default-input" type="text" .value=${this.value} ?readonly=${this.readonly} tabindex="0"/>
      </slot>
    </div>
      `;
    }
    setValueInSlottedContent() {
        this.slottedValues.forEach((child) => {
            const slotChild = child;
            if (slotChild.value !== undefined) {
                slotChild.value = this.value;
            }
        });
    }
};
__decorate([
    property()
], CustomSelect.prototype, "value", null);
__decorate([
    property({ type: Boolean })
], CustomSelect.prototype, "readonly", void 0);
__decorate([
    queryAssignedElements({ selector: '*', flatten: true })
], CustomSelect.prototype, "slottedValues", void 0);
CustomSelect = __decorate([
    customElement('st-form-select')
], CustomSelect);
export { CustomSelect };
