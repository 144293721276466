var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from './lit';
let SlideShow = class SlideShow extends LitElement {
    static { this.styles = css `
    .wrapper {
      --wrapper-width: 15rem;
      max-width: var(--wrapper-width);
      overflow-x: auto;
    }
    .root {
      display:flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 10rem;
      >div {
        background-color: silver;
        border: solid 1px black;
        box-sizing: border-box;
        height: 3rem;
        padding: 4px;
        width: var(--wrapper-width);
      }
    }

    ::slotted(div){
      background-color: silver;
        border: solid 1px black;
        box-sizing: border-box;
        height: 3rem;
        padding: 4px;
        width: var(--wrapper-width);
    }
  `; }
    constructor() {
        super();
        this.message = '';
    }
    // Render the UI as a function of component state
    render() {
        return html `
    <div class="wrapper">
      <div class="root">
        <slot></slot>
      </div>
    </div>
    `;
    }
};
__decorate([
    property()
], SlideShow.prototype, "message", void 0);
SlideShow = __decorate([
    customElement('st-slideshow')
], SlideShow);
export { SlideShow };
